import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';

import { Document, Page, pdfjs, PDFDownloadLink } from 'react-pdf';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { FaFilePdf, FaFileDownload } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';

import styled from "styled-components"

const StyledIconButton = styled(IconButton)`
    background-color: #2c466b;
    color: white;
    width: max-content;
    padding: 6px;
    border-radius: 0;
    // margin-left: 10px;
    // margin-right: 10px;
    // margin-bottom: 8px;
    margin: 4px;
    font-size: 18px;
    flex: 1;
    &.Mui-disabled {
        background: lightgray;
    }
    &:hover {
        background-color: #2c466b;
        color: #ff8000;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: -4px;
    overflow: hidden;
`

const StyledPagerButton = styled(IconButton)`
    background-color: #2c466b;
    color: white;
    width: max-content;
    padding: 6px;
    border-radius: 0;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
    
    &.Mui-disabled {
        background: lightgray;
    }
    &:hover {
        background-color: #2c466b;
        color: #ff8000;
    }
`

const CloseButtonStyled = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`


const Pager = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        margin-bottom: 0;
        text-align: center;
        width: 55px;
    }
`

const StyledDocument = styled(Document)`
    canvas {
        max-width: 100%!important;
        object-fit: contain!important;
        height: auto!important;
        
    }
`

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '100vh',
    height: '100%',
    overflow: 'hidden',
    
    '&:focus': {
        outline: 'none',
    }
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    
    '&:focus': {
        outline: 'none',
    }
    
  },
}));

export default function TransitionsModal({ pdf, presentationTitle }) {
    const {t} = useI18next();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
     
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <ButtonWrapper>

      <StyledIconButton type="button" onClick={handleOpen}>
        {t("OPEN")}<OpenInBrowserIcon style={{marginLeft: 5}}/>
      </StyledIconButton>
      <StyledIconButton type="button" href={pdf} rel="noopener noreferrer" target="_blank" download>
        {t("DOWNLOAD")} <FaFileDownload style={{marginLeft: 5}}/>
      </StyledIconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{presentationTitle}</h2>
            <StyledDocument
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
            
            </StyledDocument>
            <Pager>
                <StyledPagerButton color="primary" aria-label="previous slide" component="span" onClick={()=>(setPageNumber(pageNumber-1))} disabled={pageNumber <= 1}>
                    <NavigateBeforeIcon fontSize="medium"/>
                </StyledPagerButton>
                <div>
                    <p>{pageNumber} / {numPages}</p>
                </div>    
                <StyledPagerButton color="primary" aria-label="next slide" component="span" onClick={()=>(setPageNumber(pageNumber+1))} disabled={pageNumber >= numPages}>    
                    <NavigateNextIcon fontSize="medium"/>
                </StyledPagerButton>
            </Pager>
            <CloseButtonStyled onClick={()=>(setOpen(false))}>
                <CloseIcon fontSize="medium" />
            </CloseButtonStyled>
          </div>
        </Fade>
      </Modal>
    </ButtonWrapper>
  );
}