import React from 'react'

import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero-our-model"
import Content from "../../components/presentations-content"

const BooksPage = () => {
    
    const {t} = useI18next();
    
    return (
        <Layout>
            <SEO title={t("PUBLICATIONS.PRESENTATIONS")}/>
            <Content />
        </Layout>
    )
}

export default BooksPage